import $ from "jquery";

// jQuery(function($) {
$(document).ready(function () {
  var $animation_elements = $(".animation-element");
  var $window = $(window);
  var white_space_active = false;
  // var stop_scrolling = true;

  $window.on("scroll resize load", check_if_in_view);

  // $('.loading-bar').addClass('half-loaded');
  // // $window.on('load', function () {
  //     $('.loading-bar').addClass('loaded');
  //     $('.header-img.center').addClass('whitespace-removed').removeClass('position-fixed');
  //     $('.klueh-first-logo').addClass('show');
  //     window.setTimeout(function() {
  //         removeWhitespace();
  //     }, 2000);
  //     window.setTimeout(function() {
  //         $('.topnav').addClass('hi-z');
  //     }, 4000);
  // });

  // $window.trigger('scroll');

  function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = window_top_position + window_height;

    $.each($animation_elements, function () {
      var $element = $(this);
      if (!$element.hasClass("in-view")) {
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = element_top_position + element_height;

        //check to see if this current container is within viewport
        if (
          element_bottom_position >= window_top_position &&
          element_top_position <= window_bottom_position
        ) {
          if (!white_space_active || $element.hasClass("first-view")) {
            if ($element.hasClass("text-anim")) {
              window.setTimeout(function () {
                $element.addClass("in-view");
              }, 100);
            } else {
              $element.addClass("in-view");
              if ($element.children(".skill-box").length) {
                var time = 100;
                $element.find(".skill-box").each(function () {
                  var $box = $(this);
                  window.setTimeout(function () {
                    $box.addClass("show");
                  }, time);
                  time += 100;
                });
              }
            }
          }
        }
      }
    });
  }

  $(".ats").html("@");
  $(".dots").each(function () {
    $(this).html(".");
  });
  $(".ats")
    .closest("a")
    .attr("href", "mailto:alex" + ".eric." + "schmitz" + "@" + "gmail.com");
  check_if_in_view();

  // $( window ).on('load',function() {
  //     check_if_in_view();
  // });
});
// });
